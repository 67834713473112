import { Fade } from '@chakra-ui/react';
import { countries } from 'countries-list';
import { useState } from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
} from 'react-select';

import { countryStyles } from '../../../styles/components/ReactSelectStyles';
import Icon from '../Icon/Icon';
import {
  CountryCode,
  CountryDropDown,
  CountryFlag,
  OptionCode,
  OptionFlag,
  OptionName,
  SelectWrapper,
  Wrapper,
} from './CountriesDropDown.styles';

type CountryType = {
  name: string;
  flag: string;
  countryCode: string;
};

type CountriesDropDownProps = {
  defaultCountryFlag: string;
  defaultCountryCode: string;
  isInvalid?: boolean;
  onSelectCountryCode: (countryCode: string, flag: string) => void;
};

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon type="search" size="sm" color="lightGray" />
    </components.DropdownIndicator>
  );
};

const Option = (props: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { flag, name, countryCode } = props.data.value;
  return (
    <components.Option {...props}>
      <OptionFlag>{flag}</OptionFlag>
      <OptionName>{name}</OptionName>
      <OptionCode>{countryCode}</OptionCode>
    </components.Option>
  );
};

const formattedCountries = Object.entries(countries).map((country) => {
  return {
    label: `${country[1].emoji} ${country[1].name} ${country[1].phone}`,
    value: {
      name: country[1].name,
      flag: country[1].emoji,
      countryCode: `+${country[1].phone}`,
    },
  };
});

const CountriesDropDown = ({
  defaultCountryFlag,
  defaultCountryCode,
  isInvalid,
  onSelectCountryCode,
}: CountriesDropDownProps): JSX.Element => {
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [isCountryDropDownOpen, setIsCountryDropDownOpen] =
    useState<boolean>(false);
  return (
    <Wrapper>
      <CountryDropDown
        $isInvalid={isInvalid}
        onClick={() => setIsCountryDropDownOpen(!isCountryDropDownOpen)}
      >
        <CountryFlag>
          {selectedCountry?.flag || defaultCountryFlag || '🇬🇧'}
        </CountryFlag>
        <Icon
          type={isCountryDropDownOpen ? 'upArrow' : 'downArrow'}
          size="xsm"
        />
      </CountryDropDown>

      <CountryCode>
        {selectedCountry?.countryCode || defaultCountryCode || '+44'}
      </CountryCode>
      {isCountryDropDownOpen && (
        <Fade in={isCountryDropDownOpen}>
          <SelectWrapper>
            <Select
              components={{ DropdownIndicator, Option }}
              menuIsOpen
              styles={countryStyles}
              options={formattedCountries}
              onChange={(e: any) => {
                setSelectedCountry(e.value);
                onSelectCountryCode(e.value.countryCode, e.value.flag);
                setIsCountryDropDownOpen(false);
              }}
              placeholder="Search for countries"
            />
          </SelectWrapper>
        </Fade>
      )}
    </Wrapper>
  );
};
export default CountriesDropDown;

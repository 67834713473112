import styled from 'styled-components';
import media from '@/modules/media';

export const Wrapper = styled.div<{
  $arePointerEventsDisabled?: boolean;
  $hasMaxWidth?: boolean;
  $hasMinWidth?: boolean;
}>`
  display: flex;
  width: ${(props) => props.$hasMaxWidth && '100%'};
  flex-direction: column;
  align-items: flex-start;
  pointer-events: ${(props) =>
    props.$arePointerEventsDisabled ? 'none' : 'auto'};
  min-width: ${(props) => props.$hasMinWidth && '294px'};

  ${media.custom(1200)} {
    min-width: ${(props) => props.$hasMinWidth && '294px'};
  }

  ${media.minmax(1025, 1060)} {
    min-width: ${(props) =>
      props.$hasMinWidth &&
      `calc(
      50vw - ${props.theme.rightSidebarWidth.desktop} - 100px
    )`};
  }

  ${media.custom(833)} {
    min-width: ${(props) => props.$hasMinWidth && `100%`};
  }

  & > div > div {
    border-color: #cdd5df;

    &:focus-within {
      border-color: #cdd5df;
    }
  }
  & > div:nth-child(2) {
    border-color: #cdd5df;

    &:focus-within {
      border-color: #cdd5df;
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.font.sm};
  line-height: 18px;
  font-weight: 600;
`;

export const LabelInfoText = styled.span`
  font-size: ${(props) => props.theme.font.md};
  font-weight: 400;
  color: #90959c;
  letter-spacing: -0.3px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.error800};
  white-space: nowrap;
  letter-spacing: -0.4px;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.success700};
`;

export const HelperTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.md};
  margin-top: ${(props) => props.theme.space.md};
  border: 'none';
`;

export const FullText = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.font.sm};
  line-height: ${(props) => props.theme.font.md};
  gap: ${(props) => props.theme.space.sm};
`;

export const HelperText = styled.p`
  color: ${(props) => props.theme.color.gray500};
`;

export const InformativeText = styled.p`
  font-size: ${(props) => props.theme.font.md};
  font-weight: 500;
  color: ${(props) => props.theme.color.gray700};
  margin-top: ${(props) => props.theme.space.md};
`;

export const AnnotatedText = styled.span`
  color: ${(props) => props.theme.color.gray900};
  font-weight: 700;
`;

import Image from 'next/image';
import { PhotoType } from '@/components/common/Avatar/types';
import { getEnvVars } from '@/modules/common';

import { AvatarContainer } from './Avatar.styles';

type AvatarPropsType = PhotoType & {
  profilePicture: string;
};

const avatarSizes = {
  '3xs': 16,
  xxs: 20,
  xs: 24,
  sm: 32,
  xsm: 40,
  smd: 48,
  md: 72,
  mdl: 76,
  lg: 168,
};

const Avatar = ({
  profilePicture = 'default_smile_1.png',
  size = 'md',
  radius = 'mediumCircled',
  $status,
}: AvatarPropsType): JSX.Element => {
  const isPhotoEmoji = profilePicture?.includes('default');
  const { MEDIA_URL } = getEnvVars();
  let borderRadius = '50%';

  if (radius === 'mediumCircled') {
    borderRadius = '43%';
  } else if (radius === 'largeCircled') {
    borderRadius = '38%';
  }

  return (
    <AvatarContainer
      radius={radius}
      $status={$status}
      size={size}
      className="avatar"
      $isEmoji={isPhotoEmoji}
    >
      <Image
        src={`${MEDIA_URL}/${profilePicture}`}
        loader={() => `${MEDIA_URL}/${profilePicture}`}
        width={avatarSizes[size]}
        height={avatarSizes[size]}
        style={{
          borderRadius,
        }}
        alt="profile picture"
      />
    </AvatarContainer>
  );
};

export default Avatar;
